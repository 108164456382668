<template>
    <section class="cont not-check-left">
       <!-- 面包屑 -->
       <el-row class="crumbs-box">
          <el-breadcrumb separator-class="el-icon-arrow-right">
             <el-breadcrumb-item>系统管理</el-breadcrumb-item>
             <el-breadcrumb-item>角色管理</el-breadcrumb-item>
          </el-breadcrumb>
       </el-row>
       <!-- 主体内容 -->
       <el-row class="content-flex-box">
          <!-- 侧边表格 -->
          <el-col :span="6" class="left-table-bg">
            <el-row class="left-table">
               <el-table
                     ref="sideTable"
                     v-loading="sideLoad"
                     :data="sideData"
                     highlight-current-row
                     row-key="id"
                     @row-click="(row) => handleSideSelect(row)"
                     style="width: 100%;"
                     max-height="780"
                     :stripe="true"
                     border fit >
                  <el-table-column prop="name" :label="$t('msg.org_list')"></el-table-column>
               </el-table>
            </el-row>
          </el-col>
          <!-- 主表格 -->
          <el-col :span="18" class="right-table-bg">
             <el-row class="right-table">
                <!-- 表格搜索框 -->
                <el-row class="search-box">
                   <el-row class="search-row">
                      <el-row class="search-item">
                         <label>租户/商户：</label>
                         <el-cascader
                               class="width-220"
                               v-model="publics.companyId"
                               @change="changeCompany"
                               :props="companies"
                               :show-all-levels="false">
                         </el-cascader>
                      </el-row>
                      <el-row class="search-item">
                         <label>角色：</label>
                         <el-input class="width-220"  v-model="param.roleNameCode" :placeholder="$t('msg.role_name_code')" clearable></el-input>
                      </el-row>
                      <el-button class="bg-gradient" type="primary"  @click="handleQuery(true)"><span v-text="$t('msg.search')">搜索</span></el-button>
                      <el-button type="primary"  plain @click="handleQuery(false)"><span v-text="$t('msg.reset')">重置</span></el-button>
                   </el-row>
                </el-row>
                <!-- 表格组件 -->
                <el-row class="table-box">
                   <div class="table-head-btn">
                     <el-row></el-row>
                      <el-row class="right-btn">
                         <el-button class="m-bottom-5" type="primary"  plain @click="hotelAuthorize"><span>酒店授权</span></el-button>
                         <el-button class="m-bottom-5" type="primary"  plain @click="resourcesAuthorize"><span v-text="$t('msg.resource_auth')">资源授权</span></el-button>
                         <el-button class="m-bottom-5" type="primary"  plain @click="userAuthorize"><span v-text="$t('msg.user_auth')">用户授权</span></el-button>
                         <el-button class="m-bottom-5 bg-gradient" type="primary" icon="el-icon-plus"  @click="handleAdd"><span v-text="$t('msg.add_role')">新增角色</span></el-button>
                      </el-row>
                   </div>
                   <el-table
                         ref="mainTable"
                         v-loading="tableLoad"
                         :data="tableData"
                         highlight-current-row
                         @current-change="handleTableSelect"
                         style="width: 100%"
                         :stripe="true"
                         border fit >
                      <el-table-column prop="number" label="序号" width="60">
                         <template slot-scope="scope">
                            {{scope.$index + 1}}
                         </template>
                      </el-table-column>
                      <el-table-column prop="roleName" :label="$t('msg.role_name')" min-width="130"></el-table-column>
                      <el-table-column prop="roleCode" :label="$t('msg.role_encoding')" width="150"></el-table-column>
                      <el-table-column prop="" :label="$t('msg.no_admin')"></el-table-column>
                      <el-table-column :label="$t('msg.status')">
                         <template slot-scope="scope">
                            <p>{{scope.row.state | filterState}}</p>
                         </template>
                      </el-table-column>
                      <el-table-column prop="orgName" :label="$t('msg.affiliated_org')" width="100"></el-table-column>
                      <el-table-column prop="companyName" :label="$t('msg.tenant_store')" min-width="170"></el-table-column>
                      <el-table-column prop="createTime" :label="$t('msg.create_time')" min-width="150"></el-table-column>
                      <el-table-column :label="$t('msg.operating')" fixed="right" min-width="100">
                         <template slot-scope="scope">
                            <el-button @click="handleEdit(scope.row)" type="text">
                               <el-link type="primary" v-text="$t('msg.edit')">编辑</el-link>
                            </el-button>
                            <el-button v-show="scope.row.roleType !== 'MANAGER'" @click="handleDel(scope.row.id)" type="text" style="color: #F56C6C;">
                               <el-link type="danger" v-text="$t('msg.remove')">删除</el-link>
                            </el-button>
                         </template>
                      </el-table-column>
                   </el-table>
                   <pagination :total="total" :page-size="param.limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="handlePaging"/>
                </el-row>
             </el-row>
          </el-col>
          <!-- 新增/编辑弹窗 -->
          <el-dialog :title="`${action === 'add' ? '添加' : '编辑'}成功`" :visible.sync="isShow"  :before-close="handleClose" width="500px">
             <el-form class="form-dialog-box" :model="ruleForm" :rules="rules" ref="ruleForm">
                <el-form-item label="组织名称：" v-show="orgIsShow">
                   <span v-text="orgName"></span>
                </el-form-item>
                <el-form-item label="角色名称：" prop="roleName">
                   <el-input v-model="ruleForm.roleName" :placeholder="$t('msg.role_name')" clearable></el-input>
                </el-form-item>
                <el-form-item label="角色编码：" prop="roleCode">
                   <el-input v-model="ruleForm.roleCode" :placeholder="$t('msg.role_encoding')" clearable></el-input>
                </el-form-item>
                <el-form-item label="是否有效：">
                   <el-switch v-model="ruleForm.state" active-value="ENABLED" inactive-value="DISABLED"></el-switch>
                </el-form-item>
                <el-form-item label="备注信息：">
                   <el-input type="textarea" v-model="ruleForm.remark" :placeholder="$t('msg.inp_remark_info')"></el-input>
                </el-form-item>
             </el-form>
             <span slot="footer" class="dialog-footer">
                  <el-button  @click="resetForm()" v-text="$t('msg.reset')">重置</el-button>
                  <el-button class="bg-gradient" type="primary" @click="handleSave()" v-text="$t('msg.save')">保存</el-button>
             </span>
          </el-dialog>
          <!-- 资源授权 -->
          <el-form label-width="105px">
             <el-dialog :title="$t('msg.menu_resource_grant_role')" :visible.sync="menuIsShow" width="510px">
                <div class="execute">
                   <div class="execute-room">
                      <div class="top-text"><span v-text="$t('msg.menu_list')">菜单列表</span></div>
                      <el-tree
                            ref="tree"
                            :data="menusData"
                            show-checkbox
                            node-key="id"
                            :default-expanded-keys="checkedArr"
                            :default-checked-keys="checkedArr"
                            :props="defaultProps">
                      </el-tree>
                   </div>
                </div>
                <span slot="footer" class="dialog-footer">
                     <el-button  type="primary" @click="getCheckedKeys" v-text="$t('msg.save')">保存</el-button>
                   </span>
             </el-dialog>
          </el-form>
          <!-- 用户授权 -->
          <el-form label-width="105px">
             <el-dialog :title="$t('msg.roles_granted_to_users')" :visible.sync="userIsShow" width="800px">
                <div class="execute">
                   <div class="execute-room">
                      <div class="top-text"><span v-text="$t('msg.no_user_selected')">未选择用户</span></div>
                      <el-table
                            :data="notUserData"
                            border
                            style="width: 100%;"
                            @selection-change="selectionUser"
                            :stripe="true"
                            >
                         <el-table-column type="selection"></el-table-column>
                         <el-table-column prop="userName" :label="$t('msg.user_name')"></el-table-column>
                         <el-table-column prop="nickName" :label="$t('msg.nickname')"></el-table-column>
                      </el-table>
                   </div>
                   <p @click="saveRelation"><i class="el-icon-d-arrow-right"></i></p>
                   <div class="execute-room">
                      <div class="top-text"><span v-text="$t('msg.user_selected')">已选择用户</span></div>
                      <el-table :data="userData" border style="width: 100%;" max-height="780" :stripe="true" >
                         <el-table-column prop="userName" :label="$t('msg.user_name')"></el-table-column>
                         <el-table-column prop="nickName" :label="$t('msg.nickname')"></el-table-column>
                         <el-table-column :label="$t('msg.remove')" fixed="right">
                            <template slot-scope="scope">
                               <el-button @click="handleUntying(scope.row.id)" type="text" style="color: #F56C6C;" icon="el-icon-delete" >
                                  <el-link type="danger" v-text="$t('msg.remove')">删除</el-link>
                               </el-button>
                            </template>
                         </el-table-column>
                      </el-table>
                   </div>
                </div>
             </el-dialog>
          </el-form>
          <!-- 酒店授权 -->
          <el-form label-width="105px">
             <el-dialog title="酒店授权" :visible.sync="hotelIsShow">
                <el-row>
                   <el-col class="table-box" :span="11">
                      <div class="top-text"><span>未选酒店</span></div>
                      <el-table
                            :data="unAuthHotels"
                            border
                            height="252"
                            @selection-change="selectionHotels"
                            :stripe="true"
                            >
                         <el-table-column type="selection"></el-table-column>
                         <el-table-column prop="hotelName" label="酒店名称"></el-table-column>
                      </el-table>
                      <el-pagination
                            small
                            layout="prev, pager, next"
                            @current-change="changeUnHotelPage"
                            :page-size="hotelLimit"
                            :total="unAuthHotelTotal">
                      </el-pagination>
                   </el-col>
                   <el-col class="table-box-arrow" :span="2">
                      <p @click="saveAuthHotels"><i class="el-icon-d-arrow-right"></i></p>
                   </el-col>
                   <el-col class="table-box" :span="11">
                      <div class="top-text"><span>已选酒店</span></div>
                      <el-table
                            :data="authHotels"
                            border
                            height="252"
                            :stripe="true"
                            >
                         <el-table-column prop="hotelName" label="酒店名称"></el-table-column>
                         <el-table-column :label="$t('msg.remove')" fixed="right">
                            <template slot-scope="scope">
                               <el-button @click="delAuthHotel(scope.row.id)" type="text" style="color: #F56C6C;" icon="el-icon-delete" >
                                  <el-link type="danger" v-text="$t('msg.remove')">删除</el-link>
                               </el-button>
                            </template>
                         </el-table-column>
                      </el-table>
                      <el-pagination
                            small
                            layout="prev, pager, next"
                            @current-change="changeHotelPage"
                            :page-size="hotelLimit"
                            :total="authHotelTotal">
                      </el-pagination>
                   </el-col>
                </el-row>
             </el-dialog>
          </el-form>
       </el-row>
    </section>
</template>

<script>
import { urlObj } from '@/api/interface'
import { system } from '@/api/interface/system'
export default {
   data(){
      let self = this
      return{
         sideData: [],        // 侧边列表数据
         tableData: [],       // 表格数据
         menusData: [],       // 菜单列表
         menuPar: [],         // 被选中的父节点列表
         notUserData: [],     // 未授予角色的用户列表
         userData: [],        // 授予角色的用户列表
         sideLoad: true,      // 侧边栏开启加载效果
         tableLoad: true,     // 主体表格开启加载效果
         boxIcon: true,       // 侧边栏折叠状态
         isShow: false,       // 是否显示添加/编辑弹窗
         menuIsShow: false,   // 是否显示资源授权弹窗
         userIsShow: false,   // 是否显示用户授权弹窗
         hotelIsShow: false,  // 是否显示酒店授权弹窗
         orgName: '',         // 组织名称
         orgIsShow: false,    // 组织是否显示
         id: '',              // 编辑所用id
         roleId: '',          // 获取菜单所用id
         total: 0,            // 总条数
         action: 'add',       // 操作类型
         companyName: '',     // 租户/商户名称
         /* 用户（未）授权列表 */
         orgIdAuth: '',       // 根据组织id获取未授权用户列表
         companyIdAuth: '',   // 根据单位id获取未授权用户列表
         userLimit: 5,        // 限制用户列表的每页显示数
         userPage: 1,         // 用户列表当前第一页
         checkedKeys: [],     // 保存资源授权的id数组
         userIds: [],         // 选中的未授权用户列表
         /* 树形控件 配置项 */
         defaultProps: {
            children: 'children',
            label: 'name'
         },
         checkedArr: [],    // 菜单列表默认选中的id数组
         companies: {         // 公司列表
            lazy: true,
            checkStrictly: true,
            async lazyLoad(node, resolve){
               const { value,hasChildren } = node
               if (hasChildren === false) return resolve([])
               let data = await self.getCompanies(value)
               resolve(data)
            }
         },
         publics: {
            companyId: '',   // 租户/商户ID
            orgId: '',
         },
         param: {
            page: 1,
            limit: 1,
            roleNameCode: '', // 角色名称/编码共有字段
         },
         ruleForm: {
            roleName: '',      // 角色名称
            roleCode: '',      // 角色编码
            state: 'ENABLED',  // 角色状态
            remark: '',        // 备注信息
         },
         rules: {
            roleName: [
               { required: true, message: '请输入角色名称', trigger: 'blur' }
            ],
            roleCode: [
               { required: true, message: '请输入角色编码', trigger: 'blur' }
            ]
         },
         /* 授权资源提交字段 */
         menuIds: [],       // 新增菜单选项id
         delMenuIds: [],    // 移除菜单选项id
         /* 酒店授权 */
         unAuthHotels: [],      // 未授权酒店列表
         authHotels: [],        // 已授权酒店列表
         unAuthHotelTotal: 0,   // 未授权酒店总数
         authHotelTotal: 0,     // 已授权酒店总数
         roleHotelList: [],     // 酒店和商户ID
         hotelLimit: 0,         // 酒店列表每页数
         unHotelPage: 1,        // 未授权酒店列表当前页
         hotelPage: 1,          // 已授权酒店列表当前页
         /* 弹窗提示文本 */
         name_no_null: "名称不能为空！",
         number_no_null: "编码不能为空！",
         add_success: "添加成功！",
         del_success: "删除成功！",
         update_success: "修改成功！",
         select_success: "请先选中角色！",
         resources_auth_success: '资源授权成功！',
         role_conferring_success: '角色授予成功！',
         untying_user_success: '解绑用户成功！',
         userIds_no_null: '请先选中用户！',
         menuIds_no_null: '菜单未经修改！',
         hotel_auth_success: '酒店授权成功！',
         hotel_remove_success: '酒店移除成功',
         confirm: '确定',
         cancel: '取消',
         confirm_remove: '确定移除？',
         prompt: '提示！'
      }
   },
   mounted() {
      this.param.limit = sessionStorage.getItem('pageSize') * 1
      this.initForm = { ...this.ruleForm }
      this.getRole()
      this.getOrg()
   },
   inject: ['reload'],
   methods: {
      // 获取角色列表
      getRole(){
         const param = { ...this.param, ...this.publics }
         const url = system.rolePaging
         this.$axios.post(url, param).then(res => {
            if(res.searchCount){
               this.total = res.total
               this.tableLoad = false
               this.tableData = res.records
            }
         })
      },
      // 获取组织列表
      getOrg(){
         const param = { companyId: this.publics.companyId }
         const url = system.org
         this.$axios.post(url, param, 'json').then(res => {
            if(res.success) this.sideLoad = false; this.sideData = res.children
         })
      },
      // 获取租户/商户列表
      getCompanies(companyId){
         return new Promise((resolve, reject) => {
            const url = system.companies
            const { accountType } = JSON.parse(sessionStorage.getItem('userInfo'))
            let param = {}
            if (accountType === 'PLATFORM' ||
                  accountType === 'TENANT') param.types = ['TENANT']
            if (accountType === 'TENANT') param.types = ['TENANT']
            if (companyId) param.types = ['STORE', 'BUSINESS_SHOPPING'], param.tenantId = companyId
            this.$axios.post(url, param, 'json').then(res => {
               if (res.records) {
                  let companies = res.records
                  companies = companies.map(item => {
                     let leaf
                     item.leaf && (leaf = true)
                     return {value: item.id, label: item.companyName, leaf: leaf}
                  })
                  resolve(companies)
               }
            })
         })
      },
      // 改变单位当前选中
      changeCompany(val){
         if (val.length === 1) return this.publics.companyId = val[0]
         this.publics.companyId = val[1]
      },
      // 搜索角色
      handleQuery(bool){
         if (bool) return this.getOrg(), this.getRole()
         this.param.roleNameCode = ''
         this.publics.companyId = ''
         this.companyName = ''
      },
      // 改变显示条数
      pageChange(num){
         this.param.limit = num
         this.getRole()
      },
      // 改变当前页
      handlePaging(num){
         this.param.page = num
         this.getRole()
      },
      // 侧边表格当前选中
      handleSideSelect: (() => {
         let id
         return function (row) {
            if (row.id !== id){
               id = row.id
               this.publics.orgId = row.id
               this.publics.companyId = row.companyId
               this.getRole()
               this.orgIsShow = true
               this.orgName = row.name
               return
            }
            id = ''
            this.publics.orgId = ''
            this.publics.companyId = ''
            this.orgIsShow = false
            this.orgName = ''
            this.$refs.sideTable.setCurrentRow();
            this.getRole()
         }
      })(),
      // 主体表格当前选中
      handleTableSelect(val){
         this.roleId = val.id
         this.orgIdAuth = val.orgId
         this.companyIdAuth = val.companyId
      },
      // 添加角色
      handleAdd(){
         this.isShow = true
         this.action = 'add'
      },
      // 编辑角色
      handleEdit(row){
         for (let k in row) k in this.ruleForm && (this.ruleForm[k] = row[k])
         this.action = 'edit'
         this.isShow = true
         this.orgIsShow = true
         this.orgName = row.orgName
         this.id = row.id
      },
      // 重置表单
      resetForm(){
         this.ruleForm = { ...this.initForm }
      },
      // 保存角色
      handleSave(){
         this.$refs.ruleForm.validate(valid => {
            if (!valid) return
            let url = system.AddRole
            let param = { ...this.ruleForm, ...this.publics }
            if (this.action === 'edit'){
               url = system.editRole
               param.id = this.id
               for (let k in this.publics) delete param[k]
            }
            this.$axios.post(url, param).then(res => {
               if (res.success) {
                  this.$message({
                     showClose: true,
                     message: `${this.action === 'add' ? '添加' : '编辑'}成功`,
                     type: 'success'
                  })
                  this.handleClose()
                  this.getRole()
               }
            })
         })
      },
      // 关闭弹窗
      handleClose(){
         this.isShow = false
         this.resetForm()
      },
      // 删除角色
      handleDel(id){
         this.$confirm(this.confirm_remove, this.prompt, {
            confirmButtonText: this.confirm,
            cancelButtonText: this.cancel,
            type: 'warning'
         }).then(() => {
            let ids = []; ids.unshift(id)
            const param = { ids: ids.toString() }
            const url = system.DelRole
            this.$axios.post(url, param).then(res => {
               if (res.success){
                  this.$message({
                     showClose: true,
                     message: this.del_success,
                     type: 'success'
                  })
                  this.getRole()
               }
            })
         })
      },
      // 资源授权
      resourcesAuthorize(){
         if (!this.roleId) return this.$message(this.select_success)
         this.menuIsShow = true
         // 清空已选中的元素id
         this.checkedArr = []
         this.getMenus()
      },
      // 资源授权 获取菜单列表
      getMenus(){
         const url = system.menus + `/${this.roleId}`
         this.$axios.get(url,{}).then(res => {
            if (res.success){
               this.menusData = res.children
               this.forMenus(this.menusData)
            }
         })
      },
      // 资源授权 递归调用，找出初始选中的菜单id
      forMenus(menusData){
         menusData.forEach(item => {
            /* 如果节点属性为true
              * 且没有子属性或者子节点为空的
              * 可以push到数组中
             */
            if (item.checked){
               if (!item.hasOwnProperty('children')){
                  this.checkedArr.push(item.id)
               }else if (item.children.length === 0){
                  this.checkedArr.push(item.id)
               }
            }
            // 如果有子属性的继续循环
            item.children && this.forMenus(item.children)
         })
      },
      // 资源授权 递归调用，找出新增/移除的菜单id
      forOldMenus(menusData){
         menusData.forEach(item => {
            // 如果元素初始时非选中且在提交时选中了该元素为新增项
            if (!item.checked && this.checkedKeys.indexOf(item.id) > -1){
               this.menuIds.push(item.id)    // 找到新增的菜单选项
            }
            if (item.checked && this.checkedKeys.indexOf(item.id) === -1){
               this.delMenuIds.push(item.id) // 找到移除的菜单选项
            }
            if (item.hasOwnProperty('children') && item.children.length !== 0){
               this.forOldMenus(item.children)
            }
         })
      },
      // 资源授权 保存操作
      getCheckedKeys(){
         // 每次保存授权操作 清空上次存储的数据
         this.menuIds = []
         this.delMenuIds = []
         // 获取当前选中及半选中的元素id
         this.checkedKeys = [...this.$refs.tree.getCheckedKeys(), ...this.$refs.tree.getHalfCheckedKeys()]
         this.forOldMenus(this.menusData)
         // 表单验证
         if (this.delMenuIds.length < 1 && this.menuIds.length < 1){
            this.$message({
               showClose: true,
               message: this.menuIds_no_null,
               type: 'warning'
            })
            return
         }
         const param = {
            delMenuIds: this.delMenuIds,
            menuIds: this.menuIds,
            roleId: this.roleId
         }
         const url = system.authRoleMenu
         this.$axios.post(url, param, 'json').then(res => {
            if (res.success){
               this.$message({
                  showClose: true,
                  message: this.resources_auth_success,
                  type: 'success'
               })
            }
            this.menuIsShow = false
         })
      },
      // 用户授权
      userAuthorize(){
         if (!this.roleId) return this.$message(this.select_success)
         this.userIsShow = true
         this.getNotUser()
         this.getHaveUser()
      },
      // 用户授权 获取未授予角色的用户列表
      getNotUser(){
         let url = system.unUserByRole + `/${this.roleId}`
         if (this.orgIdAuth !== ''){
            url = `${url}?limit=10&page=1&orgId=${this.orgIdAuth}`
         }else if (this.companyIdAuth !== ''){
            url = `${url}?limit=10&page=1&companyId=${this.companyIdAuth}`
         }else {
            url = `${url}?limit=10&page=1`
         }
         this.$axios.get(url,{}).then(res => {
            if (res.searchCount) this.notUserData = res.records
         })
      },
      // 用户授权 获取已授予角色的用户列表
      getHaveUser(){
         const url = system.userByRole + `/${this.roleId}?limit=${this.userLimit}&page=${this.userPage}`
         this.$axios.get(url,{}).then(res => {
            if (res.searchCount) this.userData = res.records
         })
      },
      // 用户授权 多选未授权用户
      selectionUser(val){
         this.userIds = val.map(item => item.id)
      },
      // 用户授权 保存授予角色操作
      saveRelation(){
         // 表单提交验证
         if (this.userIds.length === 0) {
            this.$alert(this.userIds_no_null, '', {
               confirmButtonText: this.confirm,
               type: 'error'
            })
            return
         }
         const param = {
            roleId: this.roleId,
            userIds: this.userIds,
            type: 'ROLE'
         }
         const url = system.authRoleUser
         this.$axios.post(url, param, 'json').then(res => {
            if (res.success){
               this.$message({
                  showClose: true,
                  message: this.role_conferring_success,
                  type: 'success'
               })
               this.getNotUser()
               this.getHaveUser()
            }
         })
      },
      // 用户授权 用户与角色解绑
      handleUntying(row){
         let delUserIds = []
         delUserIds.push(row)
         const param = {
            delUserIds,
            roleId: this.roleId,
            type: 'ROLE'
         }
         const url = system.authRoleUser
         this.$axios.post(url, param, 'json').then(res => {
            if (res.success){
               this.$message({
                  showClose: true,
                  message: this.untying_user_success,
                  type: 'success'
               })
               this.getNotUser()
               this.getHaveUser()
            }
         }).catch(error => {
            this.$message.error(error)
         })
      },
      // 酒店授权
      hotelAuthorize(){
         if (!this.roleId) return this.$message(this.select_success)
         this.hotelIsShow = true
         this.hotelLimit = 5
         this.getUnAuthHotels()
         this.getAuthHotels()
      },
      // 酒店授权 获取未授权角色的酒店列表
      getUnAuthHotels(){
         const url = system.unAuthHotelPage
         const param = {
            roleId: this.roleId,
            limit: this.hotelLimit,
            page: this.unHotelPage
         }
         this.$axios.post(url, param).then(res => {
            if (res.success){
               this.unAuthHotelTotal = res.total
               this.unAuthHotels = res.records
            }
         })
      },
      // 酒店授权 改变未授权酒店列表当前页
      changeUnHotelPage(num){
         this.unHotelPage = num
         this.getUnAuthHotels()
      },
      // 酒店授权 获取已授权角色的酒店列表
      getAuthHotels(){
         const url = system.authHotelPage
         const param = {
            roleId: this.roleId,
            limit: this.hotelLimit,
            page: this.hotelPage
         }
         this.$axios.post(url, param).then(res => {
            if (res.success){
               this.authHotelTotal = res.total
               this.authHotels = res.records
            }
         })
      },
      // 酒店授权 改变已授权酒店列表当前页
      changeHotelPage(num){
         this.hotelPage = num
         this.getAuthHotels()
      },
      // 酒店授权 多选未授权酒店
      selectionHotels(row){
         this.roleHotelList = row.map(item => ({hotelId: item.id, companyId: item.storeId}))
      },
      // 酒店授权 保存角色授权酒店
      saveAuthHotels(){
         const url = system.authRoleHotel
         const param = {
            roleId: this.roleId,
            roleHotelList: this.roleHotelList
         }
         this.$axios.post(url, param, 'json').then(res => {
            if (res.success){
               this.$message({
                  showClose: true,
                  message: this.hotel_auth_success,
                  type: 'success'
               })
               this.getUnAuthHotels()
               this.getAuthHotels()
            }
         })
      },
      // 酒店授权 删除已授权酒店
      delAuthHotel(hotelId){
         const url = system.delRoleHotel
         const param = {
            hotelId,
            roleId: this.roleId
         }
         this.$axios.post(url, param, 'json').then(res => {
            if (res.success){
               this.$message({
                  showClose: true,
                  message: this.hotel_remove_success,
                  type: 'success'
               })
               this.getUnAuthHotels()
               this.getAuthHotels()
            }
         })
      }
   },
   filters: {
      filterState(val){
         switch (val) {
            case "ENABLED":
               return val = '启用'
            case "DISABLED":
               return val = '禁用'
         }
      }
   }
}
</script>

<style lang="scss" scoped>
   .top-text{
      font-size: 14px;
      padding-left: 20px;
      height: 40px;
      line-height: 40px;
      border: 1px solid #ebeef5;
   }
   .table-box-arrow{
      text-align: center;
   }
   .el-icon-d-arrow-right{
      margin-top: 200%;
      font-size: 1.8rem;
      cursor: pointer;
   }

    .execute{
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        padding: 0 2%;
    &>div{
         width: 100%;
     }
    .execute-room{
        border: solid 1px #EBEEF5;
        display: flex;
        flex-direction: column;
    }
    .top-text{
        font-size: 14px;
        padding-left: 20px;
        height: 40px;
        line-height: 40px;
        border: 1px solid #EBEEF5;
    }
    }
</style>
